/* playlistFunctions.js */

import { localSettings, getBibleId } from "./localSettings.js";
import { getCategoryToggle } from "./categoryFunctions.js";
import { getModeParm }  from './getModeParm';

const getStorageTag = (bibleId) => {
  const {isBeta} = getModeParm();
  let storageTag = `playlist-${isBeta?"beta-":""}${bibleId}`;
  return storageTag;
}

/**
 * 
 * @param {array} playlist: List of audio content queued for playback
 * Stores the playlist in local storage under the bibleid
 */
export const savePlaylist = (playlist) => {
  let bibleId = getBibleId();
    if (bibleId) {
      localSettings('setItem', getStorageTag(bibleId), playlist);
    } else {
      console.error("No Bible ID to save playlist.");
    }
};

/**
 * 
 * @returns {array} playlist array from local storage for the bibleId
 */
export const getPlaylist = () => {
  let savedPlaylist = [];  
    let bibleId = getBibleId();
    if (bibleId) {
      savedPlaylist = localSettings('getItem', getStorageTag(bibleId));
      //return savedPlaylist ? JSON.parse(savedPlaylist) : [];
    }
  return savedPlaylist;
};

/**
 * @param {string} id : id of the playlist card
 * @returns {array} playlist array from local storage for the bibleId
 */
export const getPlayListCard = (id) => {
  let playList = getPlaylist();
  if (id !== undefined && playList && playList.length > 0) {
    return playList.find(card => card.id === id);
  } else {
    console.log("getPlayListCard not found:", "id:", id, "playList:", playList)
    return null;
  }
}

/**
 * @param {string} id : id of the playlist card
 * @returns {array} playlist array from local storage for the bibleId
 */
export const getPlayListCardByIndex = (index) => {
  let playList = getPlaylist();
  // console.log("getPlayListCardByIndex", index, playList)
  if (index !== undefined && playList && playList.length > 0) {
    return playList.find(card => card.cardIndex === index);
  } else {
    console.log("playlistFunctions - getPlayListCardByIndex card not found:", "index:", index, "playList:", playList)
    return null;
  }
}

/**
 * @param {string} id : id of the playlist card
 * @returns {array} the next largest index value for the playlist.  Some index values may be skipped.
 */
export const getPlayListNextIndex = () => {
  let playList = getPlaylist();
  let maxIndex = 0;
  if (playList && playList.length > 0) {    
    for (let i = 0; i < playList.length; i++) {
      if (playList[i].cardIndex > maxIndex) {
        maxIndex = playList[i].cardIndex;
      }
    }
  }
  return maxIndex+1;
}

/**
 * @param {string} trackIndex : trackIndex of the current cardIndex
 * @returns {array} the next largest track index value after the current trackindex
 */
export const getPlayListNextTrack = (trackIndex) => {
  let playList = getPlaylist();
  let restOfList = [];
  let smallestIndex = null;
  // console.log("getPlayListNextTrack", trackIndex, playList)
  if (trackIndex !== undefined && playList && playList.length > 0) {
    restOfList = playList.filter(card => card.cardIndex > trackIndex);
    if (restOfList && restOfList.length > 0) {
      // console.log("getPlayListNextTrack - restOfList", restOfList);
      // Find the index of the smallest value for 'cardIndex'
      smallestIndex = restOfList.reduce((min, current) => {
        return current.cardIndex < min ? current.cardIndex : min;
      }, restOfList[0].cardIndex);
    }
  } 
  // console.log("getPlayListNextTrack - smallestIndex", smallestIndex);
  if (!smallestIndex) {
    console.log("playlistFunctions - getPlayListNextTrack - no index greater than :",  trackIndex , " found.");
  }
  return smallestIndex;
    
}

/**
 * @param {string} id : id of the playlist card
 * @returns {boolean} true if the card with the given id exists in the playlist, false otherwise
 */
export const addToPlayList = ({
  cardId=null,
  cardType=null,
  cardMode=null,
  cardBook=null,
  cardParent=null,
  cardSection=null,
  cardName=null,
  cardPassageAudio=null,
  cardPassageFileName=null,
  cardPassage=null,
  cardCategory=null,
  cardCategoryId=null,
  cardDuration=null,
  cardImage=null,
  cardAudioTitle=null,
  cardColor=null,
  showCard=null,
  overrideDelete=false,
  cardNotes=null,   // if included then add the notes related to the selected passage card
  dispatch=null
  }
) => {  
  
  const playListAdd = (card) => {
    // console.log("Add card to playlist: ",  card);
    // Use replace to avoid duplicates
    if (dispatch) {
      dispatch({ type: 'REPLACE', payload: card });
    } else {
      console.error("addToPlayList - dispatch not defined");
    }
  };

  let bibleId = getBibleId();
  let nextIndex = 0;
  let playIndex = 0;
  // console.log("playListAdd - cardId:", cardId);
  let thisCard = getPlayListCard(cardId);
  // console.log("getPlayListCard - thisCard:", thisCard);
  if (thisCard) {

    // Card Id already in playlist: Refresh with all notes related to this section in case any categories changed status
    nextIndex = thisCard.cardIndex;
    playIndex = nextIndex;
    if (cardNotes && cardNotes.length > 0) {
      cardNotes.forEach(note => {
        let showCard = getCategoryToggle(bibleId,note.categoryId);
        if (showCard) {
          thisCard = getPlayListCard(note.id);
          if (thisCard) {
            // console.log("playActions - audio note card found",  thisCard);
            nextIndex = thisCard.cardIndex;
          } else {
            // only insert new notes if not already in the list
            nextIndex++;
            playListAdd(
              {
              "cardIndex":nextIndex,
              "id":note.id,
              "cardType":"note",
              "cardMode":cardMode,
              "cardBook":cardBook,
              "cardParent":cardParent,
              "cardSection":cardSection,
              "cardName":note.title,
              "cardPassageAudio":note.passageAudio,
              "cardPassageFileName":note.passageFileName,
              "cardPassage":note.passage,
              "cardCategory":note.category,
              "cardCategoryId":note.categoryId,
              "cardDuration":note.duration,
              "cardImage":note.image,
              "cardAudioTitle":note.audioTitle,
              "cardColor":note.color,
              "cardShow":true,
              "overrideDelete":overrideDelete
            });
          }
        }
      });
    }

  } else {
    // Card Id not found in playlist: Find the next available index and insert the card
    // Also insert any related notes

    nextIndex = getPlayListNextIndex();
    playIndex = nextIndex;
    // console.log("Adding new card. getPlayListNextIndex", nextIndex);
    // This may be either a passage or a note
    playListAdd(
      {
      "cardIndex":nextIndex,
      "id":cardId,
      "cardType":cardType,
      "cardMode":cardMode,
      "cardBook":cardBook,
      "cardParent":cardParent,
      "cardSection":cardSection,
      "cardName":cardName,
      "cardPassageAudio":cardPassageAudio,
      "cardPassageFileName":cardPassageFileName,
      "cardPassage":cardPassage,
      "cardCategory":cardCategory,
      "cardCategoryId":cardCategoryId,
      "cardDuration":cardDuration,
      "cardImage":cardImage,
      "cardAudioTitle":cardAudioTitle,
      "cardColor":cardColor,
      "cardShow":showCard,
      "overrideDelete":overrideDelete  // only override the section level delete
    });

    // If notes are associated with the passage, add those depending on the category filter
    if (cardNotes && cardNotes.length > 0) {
      cardNotes.forEach(note => {
        if (note.duration && note.duration !== "" && note.duration !== "0" && note.duration !== "00:00" && note.duration !== "--:--") {
          let showCard = getCategoryToggle(bibleId,note.categoryId);
          if (showCard) {
            nextIndex++;
            playListAdd(
              {
              "cardIndex":nextIndex,
              "id":note.id,
              "cardType":"note",
              "cardMode":cardMode,
              "cardBook":cardBook,
              "cardParent":cardParent,
              "cardSection":cardSection,
              "cardName":note.title,
              "cardPassageAudio":note.passageAudio,
              "cardPassageFileName":note.passageFileName,
              "cardPassage":note.passage,
              "cardCategory":note.category,
              "cardCategoryId":note.categoryId,
              "cardDuration":note.duration,
              "cardImage":note.image,
              "cardAudioTitle":note.audioTitle,
              "cardColor":note.color,
              "cardShow":true,
              "overrideDelete":false   // Do not override note deletes to permit sections to be refreshed when categories change
            });
          }
        }
      });
    }
  }
  // Returns the index of the original card - may be existing or newly added
  return playIndex;
}