// Converts input string of seconds into MM:SS format and returns as a string
export const getTime = (inputTime) => {    
    if (inputTime === undefined || inputTime === null || isNaN(inputTime) || inputTime === 0) {
      return ("00:00");
    } else {  
      //console.log("inputTime: " + inputTime)
      const parseMinutes = parseInt(inputTime / 60, 10);
      const minutes = (parseMinutes<10 ?"0":"") + parseMinutes;
      const seconds = "0"+parseInt(inputTime % 60);
      const timeString = `${minutes}:${seconds.slice(-2)}`
      return (timeString);
    }
}