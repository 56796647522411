
import React from "react";
import { ObtNavMenuItem } from "../ObtNavMenuItem";
import { useNavigate } from 'react-router-dom';
import "./style.css";

export const Support = ({ className, onLeave=null, setMobileOpen}) => {

  const navigate = useNavigate();

  const goToSectionOnHomePage = () => {
    navigate('/');
    setTimeout(() => {
      const section = document.getElementById('support-section');
      section.scrollIntoView({ behavior: 'smooth' });
    }, 0); // slight delay to allow for navigation to complete
  };
  
  return (
    <div className="support-menu dropdown-menu" >
      <div className="content-2">
        <ObtNavMenuItem      
            key={1}
            breakpoint="desktop"
            className="publishing-menu"
            // icon=""
            stateProp="default"
            text="Akuo/APM Demo"
            subtext={null}
            onClick={() => window.open('https://www.youtube.com/watch?v=2aGGJzaOSNE', '_blank')}
            />
        <ObtNavMenuItem      
          key={2}
          breakpoint="desktop"
          className="support-menu"
          // icon=""
          stateProp="default"
          text="Technical Support"
          subtext={null}
          onClick={() => goToSectionOnHomePage()}
          />
      </div>      
    </div>
  );
};


